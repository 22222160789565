import { createContext, ReactNode, useContext, useState } from 'react';
import { YelpLocationFormData } from '../../../shared/types/review.types';
import { YelpBusiness } from '../redux/integrations.slice';

export type YelpLocationContextType = {
  formValues: YelpLocationFormData;
  selectedLocation: YelpBusiness;
  updateFormValues: (values: YelpLocationFormData) => void;
  updateSelectedLocation: (values: YelpBusiness) => void;
};

export const YelpLocationContext =
  createContext<YelpLocationContextType | null>(null);

export const YelpLocationProvider = ({ children }: { children: ReactNode }) => {
  const [formValues, setFormValues] = useState<YelpLocationFormData>({
    name: '',
    address: '',
  });
  const [selectedLocation, setSelectedLocation] = useState<YelpBusiness>({
    address: '',
    image: '',
    name: '',
    phone: '',
    placeId: '',
    rating: '',
    reviews: '',
    website: '',
  });

  const updateFormValues = (values: YelpLocationFormData) => {
    setFormValues(values);
  };
  const updateSelectedLocation = (values: YelpBusiness) => {
    setSelectedLocation(values);
  };

  return (
    <YelpLocationContext.Provider
      value={{
        formValues,
        selectedLocation,
        updateFormValues,
        updateSelectedLocation,
      }}
    >
      {children}
    </YelpLocationContext.Provider>
  );
};

export const useYelpLocationContext = () => {
  const locationContext = useContext<YelpLocationContextType | null>(
    YelpLocationContext
  );
  if (!locationContext) {
    throw new Error(
      'useYelpLocationContext must be used within a YelpLocationProvider'
    );
  }

  return locationContext;
};
