import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import useQuery from '../../../shared/hooks/useQuery';
import setSession from '../../../shared/utils/setSession';

export default function ShopifyAuth() {
  const query = useQuery();
  const navigate = useNavigate();

  const jwtAccessToken = query.get('t');
  const jwtRefreshToken = query.get('r');

  useEffect(() => {
    if (jwtAccessToken && jwtRefreshToken) {
      setSession({ jwtAccessToken, jwtRefreshToken });
    }

    navigate('/manage-reviews');
  }, []);

  return null;
}
