import { FaDotCircle } from 'react-icons/fa';
import { FaRegCircle } from 'react-icons/fa6';
import { Tag } from 'rsuite';
import styled, { css } from 'styled-components';
import { PlanCycle } from '../../../types/review.types';
import FlexContainer from '../../layouts/flex-container';

const PriceCard = styled.div<{ $isSelectedPlan: boolean }>`
  min-width: 200px;
  display: flex;
  padding: 10px;
  position: relative;
  border-radius: 6px;
  border-width: 1px;
  min-height: 90px;
  border: 1px solid
    ${({ $isSelectedPlan }) => (!!$isSelectedPlan ? '#5069c8' : '#f4f2f2;')};
  align-items: flex-start !important;
  cursor: pointer;
  box-shadow: 0px 2px 15px -3px rgba(0, 0, 0, 0.1);
  ${({ $isSelectedPlan }) =>
    $isSelectedPlan &&
    css`
      box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
    `}
  &:hover {
    border-width: 1px !important;
    box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
  }
  .price-name {
    font-size: 15px;
    margin-bottom: 0;
  }

  @media (max-width: 690px) {
    width: 100%;
  }
  .selector {
    position: absolute;
    top: 5px;
    right: 5px;
  }
`;

type Props = {
  cycle?: PlanCycle;
  price: number;
  planName: 'Basic' | 'Pro' | 'Advanced';
  unit: 'forever' | 'month';
  isCurrentPlan: boolean;
  isSelectedPlan: boolean;
  monthlyPrice?: number;
  selectPlan: (plan: 'Basic' | 'Pro' | 'Advanced') => void;
};

export default function PlanCard({
  price,
  unit,
  cycle,
  isCurrentPlan,
  planName,
  isSelectedPlan,
  selectPlan,
  monthlyPrice,
}: Props) {
  return (
    <FlexContainer stack gap="5px">
      <Tag
        color="violet"
        style={{
          visibility: isCurrentPlan ? 'visible' : 'hidden',
        }}
      >
        Current plan
      </Tag>
      <PriceCard
        $isSelectedPlan={isSelectedPlan}
        role="presentation"
        onClick={() => selectPlan(planName)}
      >
        {isSelectedPlan ? (
          <FaDotCircle className="selector" color="#5069c8" />
        ) : (
          <FaRegCircle className="selector" />
        )}

        <FlexContainer
          stack
          gap="5px"
          justifyContent="flex-start"
          alignItems="flex-start"
        >
          <p className="price-name">{planName}</p>

          <FlexContainer justifyContent="flex-start" gap="5px">
            {cycle === PlanCycle.YEARLY && (
              <span className="original-price">${monthlyPrice}</span>
            )}
            <span className="price">${price}</span>
            <span className="per-month">/{unit}</span>
          </FlexContainer>
          {planName !== 'Basic' && (
            <span className="billed-annually">
              {cycle === PlanCycle.YEARLY && <>(${price * 12}) </>}Billed{' '}
              {cycle === PlanCycle.YEARLY ? 'annually' : 'monthly'}
            </span>
          )}
        </FlexContainer>
      </PriceCard>
    </FlexContainer>
  );
}
