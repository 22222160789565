import lodash from 'lodash';
import { useCallback, useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../store';
import { fetchProducts } from '../../product/redux/action';
import { productSelector } from '../../product/redux/product.slice';
import { SEARCH_DEBOUNCE_DELAY } from '../utils/constants';
export const useProducts = ({
  showPreloader = true,
}: {
  showPreloader?: boolean;
}) => {
  const dispatch = useAppDispatch();
  const [searchText, setSearchText] = useState<string>('');
  const results = useAppSelector(productSelector);

  useEffect(() => {
    dispatch(fetchProducts({ offset: results.offset, showPreloader }));
  }, [showPreloader]);

  const handleSearchText = useCallback(
    lodash.debounce((value: string) => {
      setSearchText(value);
      dispatch(
        fetchProducts({
          filter: value,
          offset: results.offset,
          showPreloader: true,
        })
      );
    }, SEARCH_DEBOUNCE_DELAY),
    []
  );

  const getProducts = useCallback(() => {
    dispatch(fetchProducts({ filter: searchText, offset: results.offset }));
  }, []);

  return {
    ...results,
    searchText,
    handleSearchText,
    getProducts,
  };
};
