import styled from 'styled-components';
import { accountSelector } from '../../features/userData/redux/userData.slice';
import { useAppDispatch, useAppSelector } from '../../store';
import { SubscriptionPlan } from '../types/review.types';
import Crown from './Crown';

const CrownWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

const PremiumFeature = ({
  showLabel,
  showIcon = true,
  message,
  forceShowIcon,
}: {
  showLabel?: boolean;
  showIcon?: boolean;
  message?: string;
  forceShowIcon?: boolean;
}) => {
  const dispatch = useAppDispatch();
  const { account } = useAppSelector(accountSelector);
  const { selectedSite } = account ?? {};
  const { plan } = selectedSite || {};

  if (!forceShowIcon && plan !== SubscriptionPlan.FREE) {
    return null;
  }

  return (
    <>
      <CrownWrapper>
        <Crown />
      </CrownWrapper>
      {/* <FlexContainer gap="10px">
      {showIcon && <Crown />}
      {showLabel && (
        <Button
          size="xs"
          color="orange"
          appearance="ghost"
          onClick={() => dispatch(toggleUpgradeModal())}
        >
          {t('shared.pro-feature')}
        </Button>
      )}
    </FlexContainer> */}
    </>
  );
};

export default PremiumFeature;
