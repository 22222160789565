import { useEffect, useState } from 'react';
import { ModalSize } from 'rsuite/esm/Modal';
import { integrationsSelector } from '../../features/integrations/redux/integrations.slice';
import { useAppSelector } from '../../store';

const useThirdPartyModals = () => {
  const {
    googleReviewsModalOpen,
    yelpReviewsModalOpen,
    appleReviewsModalOpen,
    producthuntReviewsModalOpen,
    trustpilotReviewsModalOpen,
    capterraReviewsModalOpen,
    tripadvisorReviewsModalOpen,
    airbnbReviewsModalOpen,
    amazonReviewModalOpen,
    etsyReviewsModalOpen,
    aliExpressReviewsModalOpen,
  } = useAppSelector(integrationsSelector);

  const [yelpModalSize, setYelpModalSize] = useState<ModalSize>('sm');
  const [googleModalSize, setGoogleModalSize] = useState<ModalSize>('sm');
  const [appleModalSize, setAppleModalSize] = useState<ModalSize>('sm');
  const [producthuntModalSize, setProducthuntModalSize] =
    useState<ModalSize>('sm');
  const [trustpilotModalSize, setTrustpilotModalSize] =
    useState<ModalSize>('sm');
  const [amazonModalSize, setAmazonModalSize] = useState<ModalSize>('sm');
  const [tripadvisorModalSize, setTripadvisorModalSize] =
    useState<ModalSize>('sm');
  const [capterraModalSize, setCapterraModalSize] = useState<ModalSize>('sm');
  const [airbnbModalSize, setAirbnbModalSize] = useState<ModalSize>('sm');
  const [etsyModalSize, setEtsyModalSize] = useState<ModalSize>('lg');
  const [aliExpressModalSize, setAliExpressModalSize] =
    useState<ModalSize>('lg');

  useEffect(() => {
    if (capterraReviewsModalOpen) {
      setCapterraModalSize('sm');
    }
  }, [capterraReviewsModalOpen]);

  useEffect(() => {
    if (yelpReviewsModalOpen) {
      setYelpModalSize('sm');
    }
  }, [yelpReviewsModalOpen]);

  useEffect(() => {
    if (trustpilotReviewsModalOpen) {
      setTrustpilotModalSize('sm');
    }
  }, [trustpilotReviewsModalOpen]);

  useEffect(() => {
    if (producthuntReviewsModalOpen) {
      setProducthuntModalSize('sm');
    }
  }, [producthuntReviewsModalOpen]);

  useEffect(() => {
    if (appleReviewsModalOpen) {
      setAppleModalSize('sm');
    }
  }, [appleReviewsModalOpen]);

  useEffect(() => {
    if (googleReviewsModalOpen) {
      setGoogleModalSize('sm');
    }
  }, [googleReviewsModalOpen]);

  useEffect(() => {
    if (airbnbReviewsModalOpen) {
      setAirbnbModalSize('sm');
    }
  }, [airbnbReviewsModalOpen]);

  useEffect(() => {
    if (amazonReviewModalOpen) {
      setAmazonModalSize('sm');
    }
  }, [amazonReviewModalOpen]);

  useEffect(() => {
    if (etsyReviewsModalOpen) {
      setEtsyModalSize('sm');
    }
  }, [etsyReviewsModalOpen]);

  useEffect(() => {
    if (aliExpressReviewsModalOpen) {
      setAliExpressModalSize('sm');
    }
  }, [aliExpressReviewsModalOpen]);

  return {
    googleReviewsModalOpen,
    yelpReviewsModalOpen,
    appleReviewsModalOpen,
    producthuntReviewsModalOpen,
    trustpilotReviewsModalOpen,
    capterraReviewsModalOpen,
    yelpModalSize,
    googleModalSize,
    appleModalSize,
    producthuntModalSize,
    trustpilotModalSize,
    capterraModalSize,
    tripadvisorModalSize,
    tripadvisorReviewsModalOpen,
    airbnbModalSize,
    airbnbReviewsModalOpen,
    etsyModalSize,
    etsyReviewsModalOpen,
    setYelpModalSize,
    setGoogleModalSize,
    setAppleModalSize,
    setProducthuntModalSize,
    setTrustpilotModalSize,
    setCapterraModalSize,
    setTripadvisorModalSize,
    setAirbnbModalSize,
    setEtsyModalSize,
    amazonModalSize,
    setAmazonModalSize,
    amazonReviewModalOpen,
    aliExpressReviewsModalOpen,
    aliExpressModalSize,
  };
};

export default useThirdPartyModals;
