import { createAsyncThunk } from '@reduxjs/toolkit';
import Http from '../../../services/http';
import { Loading } from '../../../shared/types/review.types';
import removeTrailingSlash from '../../../shared/utils/removeTrailingSlash';
import { RootState } from '../../../store';
import { GoogleBusinessFormData } from '../../widgetSettings/widgets/business-rich-snippets/BusinessInformationForm';

type CreateAccountPayload = {
  siteId: string;
  userId: string;
  password: string;
  email: string;
  token: string;
  redemptionCode?: string;
};

const http = new Http();

export const getSite = createAsyncThunk<
  any,
  { showPreloader: boolean },
  { rejectValue: string; state: RootState }
>(
  'getSite',
  async (_args, { rejectWithValue }) => {
    try {
      const response = await http.get(
        `${removeTrailingSlash(process.env.REACT_APP_REVIEWS_JET_API!)}/site`
      );
      return response.data;
    } catch (error) {
      return rejectWithValue((error as any).response.data);
    }
  },
  {
    condition(_, api): boolean {
      const {
        userData: { loading },
      } = api.getState();

      return loading !== Loading.PENDING;
    },
  }
);

export const getUpgradeUrl = createAsyncThunk<
  any,
  void,
  { rejectValue: string; state: RootState }
>('getUpgradeUrl', async (_args, { rejectWithValue }) => {
  try {
    const response = await http.get(
      `${removeTrailingSlash(
        process.env.REACT_APP_REVIEWS_JET_API!
      )}/settings/upgrade-url`
    );
    return response.data.upgradeUrl;
  } catch (error) {
    return rejectWithValue((error as any).response.data);
  }
});

export const forgotPassword = createAsyncThunk<
  any,
  string,
  { rejectValue: string; state: RootState }
>(
  'forgotPassword',
  async (email, { rejectWithValue }) => {
    try {
      const response = await http.post(
        `${removeTrailingSlash(
          process.env.REACT_APP_REVIEWS_JET_API!
        )}/site/forgot-password`,
        { email }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue((error as any).response.data);
    }
  },
  {
    condition(_, api): boolean {
      const {
        userData: {
          forgotPassword: { loading },
        },
      } = api.getState();

      return loading !== Loading.PENDING;
    },
  }
);

export const createAccount = createAsyncThunk<
  any,
  CreateAccountPayload,
  { rejectValue: string; state: RootState }
>(
  'createAccount',
  async (data, { rejectWithValue }) => {
    try {
      const response = await http.post(
        `${removeTrailingSlash(
          process.env.REACT_APP_REVIEWS_JET_API!
        )}/site/register`,
        data
      );
      return response.data;
    } catch (error) {
      return rejectWithValue((error as any).response.data);
    }
  },
  {
    condition(_, api): boolean {
      const {
        userData: {
          createAccount: { loading },
        },
      } = api.getState();

      return loading !== Loading.PENDING;
    },
  }
);

export const verifyAccountCreationToken = createAsyncThunk<
  any,
  string,
  { rejectValue: string; state: RootState }
>(
  'verifyAccountCreationToken',
  async (token, { rejectWithValue }) => {
    try {
      const response = await http.get(
        `${removeTrailingSlash(
          process.env.REACT_APP_REVIEWS_JET_API!
        )}/site/verify-account-creation-token?token=${token}`
      );
      return response.data;
    } catch (error) {
      return rejectWithValue((error as any).response.data);
    }
  },
  {
    condition(_, api): boolean {
      const {
        userData: {
          verifyAccountCreationToken: { loading },
        },
      } = api.getState();

      return loading !== Loading.PENDING;
    },
  }
);

export const sendVerificationEmail = createAsyncThunk<
  any,
  string,
  { rejectValue: string; state: RootState }
>(
  'sendVerificationEmail',
  async (token, { rejectWithValue }) => {
    try {
      const response = await http.post(
        `${removeTrailingSlash(
          process.env.REACT_APP_REVIEWS_JET_API!
        )}/site/send-verification-email`,
        {
          token,
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue((error as any).response.data);
    }
  },
  {
    condition(_, api): boolean {
      const {
        userData: {
          createAccount: { loading },
        },
      } = api.getState();

      return loading !== Loading.PENDING;
    },
  }
);

export const verifyAccount = createAsyncThunk<
  any,
  string,
  { rejectValue: string; state: RootState }
>(
  'verifyAccount',
  async (token, { rejectWithValue }) => {
    try {
      const response = await http.post(
        `${removeTrailingSlash(
          process.env.REACT_APP_REVIEWS_JET_API!
        )}/site/verify-account`,
        {
          token,
        }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue((error as any).response.data);
    }
  },
  {
    condition(_, api): boolean {
      const {
        userData: {
          verifyAccount: { loading },
        },
      } = api.getState();

      return loading !== Loading.PENDING;
    },
  }
);

export const login = createAsyncThunk<
  any,
  { email: string; password: string },
  { rejectValue: string; state: RootState }
>(
  'login',
  async ({ email, password }, { rejectWithValue }) => {
    try {
      const response = await http.post(
        `${removeTrailingSlash(
          process.env.REACT_APP_REVIEWS_JET_API!
        )}/site/login`,
        {
          email,
          password,
        }
      );

      return response.data;
    } catch (error) {
      return rejectWithValue((error as any).response.data);
    }
  },
  {
    condition(_, api): boolean {
      const {
        userData: {
          login: { loading },
        },
      } = api.getState();

      return loading !== Loading.PENDING;
    },
  }
);

export const logout = createAsyncThunk<
  any,
  void,
  { rejectValue: string; state: RootState }
>('logout', async (_, { rejectWithValue }) => {
  try {
    await http.post(
      `${removeTrailingSlash(
        process.env.REACT_APP_REVIEWS_JET_API!
      )}/site/logout`
    );
  } catch (error) {
    return rejectWithValue((error as any).response.data);
  }
});

export const validateRedemptionCode = createAsyncThunk<
  any,
  string,
  { rejectValue: string; state: RootState }
>(
  'validate-redemptation',
  async (code, { rejectWithValue }) => {
    try {
      const response = await http.post(
        `${removeTrailingSlash(
          process.env.REACT_APP_REVIEWS_JET_API!
        )}/app/validate`,
        { code }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue((error as any).response.data);
    }
  },
  {
    condition(_, api): boolean {
      const {
        userData: {
          validate: { loading },
        },
      } = api.getState();

      return loading !== Loading.PENDING;
    },
  }
);

export const selectSeat = createAsyncThunk<
  any,
  string,
  { rejectValue: string; state: RootState }
>(
  'select-seat',
  async (seatId, { rejectWithValue }) => {
    try {
      const response = await http.post(
        `${removeTrailingSlash(
          process.env.REACT_APP_REVIEWS_JET_API!
        )}/site/select-seat`,
        { seatId }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue((error as any).response.data);
    }
  },
  {
    condition(_, api): boolean {
      const {
        userData: { loading },
      } = api.getState();

      return loading !== Loading.PENDING;
    },
  }
);

export const addSeat = createAsyncThunk<
  any,
  { name: string; url: string },
  { rejectValue: string; state: RootState }
>(
  'add-seat',
  async ({ url, name }, { rejectWithValue }) => {
    try {
      const response = await http.post(
        `${removeTrailingSlash(
          process.env.REACT_APP_REVIEWS_JET_API!
        )}/site/add-seat`,
        { name, url }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue((error as any).response.data);
    }
  },
  {
    condition(_, api): boolean {
      const {
        userData: {
          addSeat: { loading },
        },
      } = api.getState();

      return loading !== Loading.PENDING;
    },
  }
);

export const updateSeat = createAsyncThunk<
  any,
  { siteId: string; payload: { name: string; url: string } },
  { rejectValue: string; state: RootState }
>(
  'update-seat',
  async ({ siteId, payload: { name, url } }, { rejectWithValue }) => {
    try {
      const response = await http.post(
        `${removeTrailingSlash(
          process.env.REACT_APP_REVIEWS_JET_API!
        )}/site/update-seat`,
        { siteId, name, url }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue((error as any).response.data);
    }
  },
  {
    condition(_, api): boolean {
      const {
        userData: {
          updateSeat: { loading },
        },
      } = api.getState();

      return loading !== Loading.PENDING;
    },
  }
);

export const redeemCode = createAsyncThunk<
  any,
  string,
  { rejectValue: string; state: RootState }
>(
  'redeemCode',
  async (code, { rejectWithValue }) => {
    try {
      const response = await http.post(
        `${removeTrailingSlash(
          process.env.REACT_APP_REVIEWS_JET_API!
        )}/site/redeem-code`,
        { code }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue((error as any).response.data);
    }
  },
  {
    condition(_, api): boolean {
      const {
        userData: {
          redeemCode: { loading },
        },
      } = api.getState();

      return loading !== Loading.PENDING;
    },
  }
);

export const updatePassword = createAsyncThunk<
  any,
  { password: string; token: string },
  { rejectValue: string; state: RootState }
>(
  'updatePassword',
  async ({ password, token }, { rejectWithValue }) => {
    try {
      const response = await http.post(
        `${removeTrailingSlash(
          process.env.REACT_APP_REVIEWS_JET_API!
        )}/site/update-password`,
        { password, token }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue((error as any).response.data);
    }
  },
  {
    condition(_, api): boolean {
      const {
        userData: {
          updatePassword: { loading },
        },
      } = api.getState();

      return loading !== Loading.PENDING;
    },
  }
);

export const verifyPasswordResetToken = createAsyncThunk<
  any,
  string,
  { rejectValue: string; state: RootState }
>(
  'verifyPasswordResetToken',
  async (token, { rejectWithValue }) => {
    try {
      const response = await http.post(
        `${removeTrailingSlash(
          process.env.REACT_APP_REVIEWS_JET_API!
        )}/site/verify-password-reset-token`,
        { token }
      );
      return response.data;
    } catch (error) {
      return rejectWithValue((error as any).response.data);
    }
  },
  {
    condition(_, api): boolean {
      const {
        userData: {
          verifyPasswordResetToken: { loading },
        },
      } = api.getState();

      return loading !== Loading.PENDING;
    },
  }
);

export const getBusinessInformation = createAsyncThunk<
  any,
  void,
  { rejectValue: string; state: RootState }
>(
  'getBusinessInformation',
  async (_, { rejectWithValue }) => {
    try {
      const response = await http.get(
        `${removeTrailingSlash(
          process.env.REACT_APP_REVIEWS_JET_API!
        )}/site/businesss-information`
      );
      return response.data;
    } catch (error) {
      return rejectWithValue((error as any).response.data);
    }
  },
  {
    condition(_, api): boolean {
      const {
        userData: {
          getGoogleRichSnippet: { loading },
        },
      } = api.getState();

      return loading !== Loading.PENDING;
    },
  }
);

export const updateBusinessInformation = createAsyncThunk<
  any,
  GoogleBusinessFormData,
  { rejectValue: string; state: RootState }
>(
  'updateBusinessInformation',
  async (data, { rejectWithValue }) => {
    try {
      const response = await http.update(
        `${removeTrailingSlash(
          process.env.REACT_APP_REVIEWS_JET_API!
        )}/site/update-businesss-information`,
        data
      );
      return response.data;
    } catch (error) {
      return rejectWithValue((error as any).response.data);
    }
  },
  {
    condition(_, api): boolean {
      const {
        userData: {
          updateBusinessInformation: { loading },
        },
      } = api.getState();

      return loading !== Loading.PENDING;
    },
  }
);
