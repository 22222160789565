import { createRef, useState } from 'react';
import { MdKeyboardArrowRight } from 'react-icons/md';
import { Button, Input, Message } from 'rsuite';
import styled, { css } from 'styled-components';
import FlexContainer from '../../../../../shared/components/layouts/flex-container';
import {
  ModalBody,
  ModalHeader,
} from '../../../../../shared/components/modal/modal-parts';
import StyledText from '../../../../../shared/components/styled-text';
import { useNotification } from '../../../../../shared/hooks/useNotification';
import { Loading } from '../../../../../shared/types/review.types';
import { useAppSelector } from '../../../../../store';
import { integrationsSelector } from '../../../redux/integrations.slice';
import { VALID_AMAZON_DOMAINS } from '../../../utils/constants';

const StyledInput = styled(Input)<{ error: boolean }>`
  background: #fff;
  margin-bottom: 20px;
  border-color: #cdcdcd;
  margin-top: 5px;
  outline: none !important;
  ${({ error }) =>
    error &&
    css`
      border-color: red;
    `}
`;
const StyledLabel = styled(StyledText)`
  font-size: 14px;
  color: #7b7b7b;
`;

const Wrapper = styled.div`
  padding-top: 20px;
`;

const ContinueButton = styled(Button)<{ disabled?: boolean }>`
  margin-right: auto;
`;

const AddProductPage = ({ onNext }: { onNext: (url: string) => void }) => {
  const ref = createRef<HTMLInputElement>();
  const {
    getIntegrations: { integrations },
    addingTrustpilotAccount,
  } = useAppSelector(integrationsSelector);
  const [reviewUrl, setReviewUrl] = useState('');
  const { showWarningToast } = useNotification();

  const connectAmazon = () => {
    if (ref.current) {
      ref.current.style.borderColor = '#cdcdcd';

      if (!reviewUrl) {
        ref.current.style.borderColor = 'red';
        return showWarningToast('Please enter a valid amazon product URL.');
      }

      try {
        const urlObject = new URL(reviewUrl);
        const domain = urlObject.hostname.replace(/www\./g, '');

        if (!VALID_AMAZON_DOMAINS.includes(domain)) {
          ref.current.style.borderColor = 'red';
          return showWarningToast('Please add a valid amazon product URL');
        }

        const search = encodeURIComponent(
          reviewUrl.split('?')[0].split('ref=')[0]
        );

        const alreadyAdded = (integrations?.amazon_reviews ?? []).some(
          (i) => i.pageId === search
        );

        if (alreadyAdded) {
          ref.current.style.borderColor = 'red';
          return showWarningToast('Product page already added.');
        }

        onNext(search);
      } catch (error) {
        ref.current.style.borderColor = 'red';
        return showWarningToast(
          'Please add a valid Amazon product page URL that starts with "www".'
        );
      }
    }
  };

  return (
    <>
      <ModalHeader
        title="Add your Amazon Product page"
        centerText={false}
        closeButton={false}
      />
      <ModalBody>
        <Wrapper>
          <div>
            <StyledLabel>Link to Amazon Product page</StyledLabel>
            <StyledInput
              ref={ref}
              error={null}
              onChange={(url: string) => {
                setReviewUrl(url);
                if (ref.current) {
                  ref.current.style.borderColor = '#cdcdcd';
                }
              }}
              placeholder="https://amazon.com/BRONAX-Slipers-Massage-Bathroom-Sandals/dp/B0957MKRDN/"
            />
            <ContinueButton
              appearance={
                addingTrustpilotAccount === Loading.PENDING
                  ? 'subtle'
                  : 'primary'
              }
              size="md"
              onClick={connectAmazon}
            >
              <FlexContainer gap="5px">
                <span>Next</span>
                <MdKeyboardArrowRight />
              </FlexContainer>
            </ContinueButton>
          </div>
        </Wrapper>
      </ModalBody>
    </>
  );
};

export default AddProductPage;
